import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default (ComposedComponent) => {
  const RequireAuth = (props) => {
    const history = useHistory();
    const session = useSelector((state) => state.session);
    const user = useSelector((state) => state.user.user);

    useEffect(() => {
      if (!session.isLogged && !user.id) {
        history.push('/login');
      }
    }, [session, user, history]);

    return <ComposedComponent {...props} />;
  };

  return RequireAuth;
};
