import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { InputField } from '~/components/formComponents/input';
import { DatePickerField } from '~/components/formComponents/picker';
import { Form } from '~/components/formComponents/form';
import { collectResolver } from '~/utils/yupResolver';

const CollectForm = ({ title, skippable, isCurrent, onSubmit }) => {
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: collectResolver
  });

  const submitForm = (data) => {
    onSubmit(data);
  };

  const skipForm = () => {
    onSubmit();
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title={title}
          isEditable={editable}
          showEditButton={true}
          skippable={skippable}
          isCurrent={isCurrent}
          onSave={handleSubmit(submitForm)}
          onSkip={skipForm}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}
        >
          <InputField
            control={control}
            name="PickupLocation__c"
            label="引取り元"
            tooltip="引取元を記入してください"
            defaultValue={ticket.PickupLocation__c}
            inputProps={{
              placeholder: '引取元を記入してください',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.PickupLocation__c}
            clear
            optional
          />
          <InputField
            control={control}
            name="contactName__c"
            label="担当者名"
            tooltip="担当者の名前を記入してください"
            defaultValue={ticket.contactName__c}
            inputProps={{
              placeholder: '担当者の名前を記入してください',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.contactName__c}
            clear
            optional
          />
          <DatePickerField
            control={control}
            name="collectionTime__c"
            label="拠点回収時間"
            placeholder="拠点回収時間を選択"
            defaultValue={ticket.collectionTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.collectionTime__c}
            clear
            disableFuture
            optional
          />
        </Form>
      )}
    </>
  );
};

export default CollectForm;
