import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import logo from '~/images/logo.png';

const NotFoundPage = () => {
  return (
    <div>
      <div style={{ textAlign: 'center', paddingTop: '10vh' }}>
        <img style={{ maxHeight: '30vh' }} src={logo} alt="シルトラ" />
        <h2>ページが見つかりません</h2>
        <Link to="/home">
          <Button
            style={{
              margin: 20,
              fontSize: 28,
              width: '-webkit-fill-available'
            }}
            color="primary"
            variant="contained"
          >
            ホームに戻る
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
