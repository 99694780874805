import React from 'react';
import { Controller } from 'react-hook-form';
import { Space } from 'antd';
import {
  Select,
  TextField,
  InputLabel,
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { convertLocalDateTime, getTodayDate, getTodayDatetime } from '~/utils/dates';

export const PickerField = ({
  control,
  name,
  defaultValue,
  error,
  label,
  data,
  tooltip,
  inputProps,
  placeholder,
  optional
}) => {
  return (
    <div style={{ margin: 10 }}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        render={(props) => (
          <FormControl
            variant="outlined"
            style={{ marginTop: 10 }}
            fullWidth={true}
            error={typeof error !== 'undefined'}
          >
            <InputLabel id="demo-simple-select-outlined-label">
              {label}
              {optional ? '(任意)' : ''}
            </InputLabel>
            <Select
              native
              labelId="demo-simple-select-outlined-label"
              label={
                <Space>
                  {label}
                  {optional ? '(任意)' : ''}
                </Space>
              }
              fullWidth={true}
              {...inputProps}
              {...props}
              inputProps={{
                style: {
                  fontSize: 18
                }
              }}
            >
              <option key="null" aria-label="無" value="" />
              {data.map((d) => (
                <option key={`${d.value}-${d.label}-opt`} value={d.value}>
                  {d.label}
                </option>
              ))}
            </Select>
            <FormHelperText>{error && error.message}</FormHelperText>
          </FormControl>
        )}
      />
    </div>
  );
};

export const DatePickerField = ({
  control,
  name,
  defaultValue,
  error,
  label,
  data,
  tooltip,
  inputProps,
  placeholder,
  optional,
  clear,
  disableFuture
}) => {
  const handleChange = (e) => {
    if (e.currentTarget.value) {
      if (inputProps.type === 'date')
        return control.setValue(name, e.currentTarget.value.slice(0, 10));
      if (inputProps.type === 'datetime-local')
        return control.setValue(name, e.currentTarget.value.slice(0, 16) + ':00.000'); // removing .00:000 for empty value
    }
  };

  const handleClickClear = () => {
    control.setValue(name, null);
  };

  const maxFuture = () => {
    if (!disableFuture) return null;
    if (inputProps.type === 'datetime-local') return getTodayDatetime();
    return getTodayDate();
  };

  return (
    <div style={{ margin: 10 }}>
      <Controller
        control={control}
        name={name}
        defaultValue={
          defaultValue && inputProps.type === 'datetime-local'
            ? convertLocalDateTime(defaultValue)
            : defaultValue
        }
        render={({ onBlur, ...props }) => (
          <TextField
            style={{ marginTop: 10 }}
            label={
              <Space>
                {label}
                {optional ? '(任意)' : ''}
              </Space>
            }
            onBlur={handleChange}
            fullWidth={true}
            variant="outlined"
            error={typeof error !== 'undefined'}
            helperText={error && error.message}
            {...inputProps}
            {...props}
            value={props.value || ''}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              inputProps: {
                max: maxFuture(),
                style: {
                  fontSize: 18
                }
              },
              endAdornment: control.getValues(name) && !inputProps.disabled && clear && (
                <InputAdornment position="end">
                  <IconButton aria-label="clear" onClick={handleClickClear}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </div>
  );
};
