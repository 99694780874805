import { API } from 'aws-amplify';
import {
  login,
  requestTicket as requestTicketQuery,
  getTicket,
  createTicket,
  updateTicket
} from '~/graphql/queries';

export const requestUser = async (driverId, phone) => {
  const res = await API.graphql({
    query: login,
    variables: { input: { driverId, phone } }
  });
  return res;
};

export const requestTicket = async (name) => {
  const res = await API.graphql({
    query: requestTicketQuery,
    variables: {
      input: {
        name
      }
    }
  });
  return res.data.requestTicket;
};

export const retrieveTicket = async (id) => {
  const res = await API.graphql({
    query: getTicket,
    variables: {
      input: {
        id
      }
    }
  });
  return JSON.parse(res.data.getTicket);
};

export const postTicket = async (Name, manage_id__c, Worker__c) => {
  const res = await API.graphql({
    query: createTicket,
    variables: {
      input: {
        Name,
        manage_id__c,
        Worker__c
      }
    }
  });
  return JSON.parse(res.data.createTicket);
};

export const patchTicket = async (input) => {
  const res = await API.graphql({
    query: updateTicket,
    variables: { input: JSON.stringify(input) }
  });
  return JSON.parse(res.data.updateTicket);
};
