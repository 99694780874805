import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Toast } from 'antd-mobile';
import { PhoneOutlined, LockOutlined } from '@ant-design/icons';
import { Button } from '@material-ui/core';
import { getUser, unauthUser } from '~/redux/actions';
import { NumberField } from '~/components/formComponents/input';
import { loginResolver } from '~/utils/yupResolver';

import logo from '~/images/logo.png';
import './index.css';

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { control, handleSubmit, errors } = useForm({
    resolver: loginResolver
  });
  const user = useSelector((state) => state.user.user);
  const isLoading = useSelector((state) => state.user.isLoading);
  const getUserError = useSelector((state) => state.user.error);

  useEffect(() => {
    if (isLoading) Toast.loading('ログイン');
    if (!isLoading) Toast.hide();
  }, [isLoading]);

  useEffect(() => {
    if (user && user.id) history.push('/home');
  }, [user, history]);

  useEffect(() => {
    if (getUserError)
      Toast.fail(getUserError, 2, () => {
        dispatch(unauthUser());
      });
  }, [getUserError, dispatch]);

  const onSubmit = (data) => {
    dispatch(getUser(data));
  };

  return (
    <div>
      <div style={{ textAlign: 'center', paddingTop: '10vh' }}>
        <img style={{ maxHeight: '30vh' }} src={logo} alt="シルトラ" />
      </div>
      <NumberField
        control={control}
        name="id"
        defaultValue={null}
        format={{
          format: '###'
        }}
        label={
          <>
            <LockOutlined /> ドライバーID
          </>
        }
        tooltip="ドライバーIDの数字3桁"
        inputProps={{
          autoFocus: true,
          placeholder: '3桁のドライバーID',
          autoComplete: 'off'
        }}
        error={errors.id}
        clear
      />
      <NumberField
        control={control}
        name="tel"
        defaultValue={null}
        label={
          <>
            <PhoneOutlined /> 電話番号
          </>
        }
        tooltip="登録された電話番号11桁"
        format={{
          format: '### #### ####'
        }}
        inputProps={{
          placeholder: '090 1234 5678',
          autoComplete: 'off'
        }}
        error={errors.tel}
        clear
      />
      <Button
        onClick={handleSubmit(onSubmit)}
        style={{ margin: 10, fontSize: 20, width: '-webkit-fill-available' }}
        variant="contained"
        color="primary"
        focusVisibleClassName="btn-focus"
      >
        ログイン
      </Button>
    </div>
  );
};

export default LoginPage;
