import React from 'react';
import Amplify from 'aws-amplify';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store, { persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { ActivityIndicator } from 'antd-mobile';
import { Routes } from './routes';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'antd-mobile/dist/antd-mobile.css';
import 'antd/dist/antd.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { AlertFilled } from '@ant-design/icons';

import awsExports from './aws-exports';

Amplify.configure(awsExports);

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#ffc107'
    },
    secondary: {
      main: '#111'
    },
    background: {
      paper: '#000000'
    }
  },
  typography: {
    fontSize: 18,
    color: '#fff'
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 360
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<ActivityIndicator size="small" />} persistor={persistor}>
      <ThemeProvider theme={theme}>
        {process.env.NODE_ENV !== 'production' && (
          <div
            style={{
              padding: 12,
              width: '100%',
              color: 'firebrick',
              background: 'white',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <AlertFilled />
            <div style={{ marginLeft: 10 }}>こちらはテスト環境です</div>
          </div>
        )}
        <Routes />
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
