const MINUTE_IN_MILLISECONDS = 60000;

export const convertLocalDate = (date) => {
  // local to utc
  const newDate = new Date(date.getTime() - date.getTimezoneOffset() * MINUTE_IN_MILLISECONDS);
  return newDate.toISOString().slice(0, 10);
};

export const convertLocalDateTime = (date) => {
  if (!date || date.length === 0) return undefined;

  // utc to local
  let utc = new Date(date.replace('+0000', 'Z'));
  utc.setSeconds(0, 0);

  const newDate = new Date(utc.getTime() - utc.getTimezoneOffset() * MINUTE_IN_MILLISECONDS)
    .toISOString()
    .slice(0, 16);

  return newDate;
};

export const getTodayDate = () => {
  return new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 10);
};

export const getTodayDatetime = () => {
  return new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 16);
};

export const getNowInYYMMDDHHNN = () => {
  let d = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000);
  return d.toISOString().replace(/-/g, '').replace('T', '').replace(/:/g, '').slice(2, 12);
};
