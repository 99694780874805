import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { InputField, NumberField } from '~/components/formComponents/input';
import { Form } from '~/components/formComponents/form';
import { PickerField, DatePickerField } from '~/components/formComponents/picker';
import { issueTicketResolver } from '~/utils/yupResolver';
import { convertLocalDate, getNowInYYMMDDHHNN } from '~/utils/dates';
import { Toast } from 'antd-mobile';
import { requestTicket } from '~/redux/api';

const IssueTicketForm = ({ isCurrent, onSubmit }) => {
  const user = useSelector((state) => state.user.user);
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const [noRequestNumber, setNoRequestNumber] = useState(
    !isCurrent && ticket.requestNumber__c && ticket.requestNumber__c[0] === 'N'
  );
  const { control, handleSubmit, setError, errors, reset, watch } = useForm({
    resolver: issueTicketResolver
  });
  const watchRoute = watch('routePatternNumber__c', ticket.routePatternNumber__c);

  const ticketNameIsUnique = async (ticketName) => {
    const reqTicket = await requestTicket(ticketName);
    if (reqTicket.totalSize === 0) return true;
    const ticketId = reqTicket.records[0].Id;
    if (ticketId === ticket.Id) return true;
    return false;
  };

  const submitForm = async (data) => {
    if (await ticketNameIsUnique(data.Name))
      return onSubmit({
        ...data,
        Worker__c: user.Id,
        registDate__c: convertLocalDate(data.registDate__c)
      });
    setError('Name', { message: 'すでに存在しています' });
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  const changeNoRequestNumber = () => {
    if (!noRequestNumber) {
      control.setValue('requestNumber__c', 'N-' + getNowInYYMMDDHHNN());
      errors.requestNumber__c = undefined;
    }
    if (noRequestNumber) control.setValue('requestNumber__c', '');
    setNoRequestNumber(!noRequestNumber);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title="チケット発行"
          isEditable={editable}
          showEditButton={true}
          onSave={handleSubmit(submitForm)}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}>
          <InputField
            control={control}
            name="Worker__c"
            label="輸送者"
            tooltip=""
            defaultValue={user.name}
            maxLength={3}
            inputProps={{
              disabled: true,
              autoComplete: 'off',
              onClick: () => {
                Toast.fail('輸送者は編集できません');
              }
            }}
            error={errors.Worker__c}
          />
          <InputField
            control={control}
            name="manage_id__c"
            label="輸送車番号"
            tooltip="ドライバーID"
            defaultValue={ticket.manage_id__c}
            maxLength={3}
            inputProps={{
              type: 'tel',
              disabled: true,
              autoComplete: 'off',
              onClick: () => {
                Toast.fail('輸送車番号は編集できません');
              }
            }}
            error={errors.manage_id__c}
          />
          <NumberField
            control={control}
            name="Name"
            label="伝票番号"
            tooltip="?"
            defaultValue={ticket.Name}
            format={{
              format: '######'
            }}
            inputProps={{
              placeholder: '123456',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.Name}
            clear
          />
          <PickerField
            control={control}
            name="routePatternNumber__c"
            label="ルートパターン"
            tooltip="輸送手段を選択"
            defaultValue={ticket.routePatternNumber__c}
            data={[
              { label: '送達', value: '1' },
              { label: '人送', value: '7' },
              { label: '持込', value: '8' }
            ]}
            inputProps={{
              disabled: !editable
            }}
            error={errors.routePatternNumber__c}
          />
          <NumberField
            control={control}
            name="requestNumber__c"
            label="受付NO"
            defaultValue={ticket.requestNumber__c}
            format={{
              format: noRequestNumber ? 'N-##########' : '#### #### ####',
              type: 'text'
            }}
            inputProps={{
              disabled: noRequestNumber ? true : !editable,
              autoComplete: 'off',
              placeholder: '12桁の受付NO'
            }}
            error={errors.requestNumber__c}
            clear
          />
          <FormControlLabel
            style={{ color: 'white', margin: 10 }}
            control={
              <Checkbox
                color="primary"
                disabled={!editable}
                checked={noRequestNumber}
                onChange={changeNoRequestNumber}
              />
            }
            label="受付NOを受け取っていない"
          />
          <PickerField
            control={control}
            name="transpotationMethod__c"
            label="輸送手段"
            tooltip="輸送手段を選択"
            defaultValue={ticket.transpotationMethod__c}
            data={
              watchRoute === '7'
                ? [{ label: '人送', vlaue: '人送' }]
                : [
                    { label: '車', value: '車' },
                    { label: 'キャラバン', value: 'キャラバン' },
                    { label: '2t', value: '2t' },
                    { label: '4t', value: '4t' }
                  ]
            }
            inputProps={{
              disabled: !editable
            }}
            error={errors.transpotationMethod__c}
          />
          <DatePickerField
            control={control}
            name="registDate__c"
            label="発行日"
            tooltip="伝票の発行日を選択"
            defaultValue={ticket.registDate__c}
            inputProps={{
              type: 'date',
              disabled: !editable
            }}
            error={errors.registDate__c}
            clear
            disableFuture
          />
        </Form>
      )}
    </>
  );
};

export default IssueTicketForm;
