import React from 'react';
import { Button, Typography, Grid, Box } from '@material-ui/core';

export const Form = ({
  title,
  skippable,
  description,
  isEditable,
  isCurrent,
  onSave,
  onEdit,
  onSkip,
  onCancel,
  showEditButton,
  children
}) => {
  const RenderSaveButton = () => {
    const smColSize = skippable && isCurrent ? 4 : 6;

    return (
      <Grid container spacing={1} justify="space-between" alignItems="center">
        <Box clone order={{ xs: 3, sm: 1 }}>
          <Grid item xs={12} sm={smColSize}>
            <Button
              onClick={onCancel}
              style={{ width: '100%', fontSize: 14 }}
              variant="contained"
              color="default"
              focusVisibleClassName="btn-focus"
            >
              キャンセル
            </Button>
          </Grid>
        </Box>
        {skippable && isCurrent && (
          <Box clone order={{ xs: 2, sm: 2 }}>
            <Grid item xs={12} sm={4}>
              <Button
                onClick={onSkip}
                style={{ width: '100%', fontSize: 14 }}
                variant="outlined"
                color="primary"
                focusVisibleClassName="btn-focus"
              >
                スキップ
              </Button>
            </Grid>
          </Box>
        )}
        <Box clone order={{ xs: 1, sm: 3 }}>
          <Grid item xs={12} sm={smColSize}>
            <Button
              onClick={onSave}
              style={{ width: '100%', fontSize: 14 }}
              variant="contained"
              color="primary"
              focusVisibleClassName="btn-focus"
            >
              保存
            </Button>
          </Grid>
        </Box>
      </Grid>
    );
  };

  const RenderEditButton = () => (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button
        style={{ fontSize: 14, width: '-webkit-fill-available' }}
        variant="outlined"
        onClick={onEdit}
        color="primary"
        focusVisibleClassName="btn-focus"
      >
        編集
      </Button>
    </div>
  );

  return (
    <>
      <div style={{ margin: 10 }}>
        <div style={{ fontSize: 14, margin: 10, width: '-webkit-fill-available' }}>
          <Typography style={{ fontSize: 12, color: 'white', margin: 10 }}>
            {description}
          </Typography>
          {skippable && isCurrent && (
            <Typography style={{ fontSize: 12, color: 'white', margin: 10 }}>
              このフェーズはスキップできます
            </Typography>
          )}
          {isEditable && <RenderSaveButton />}
          {!isEditable && showEditButton && <RenderEditButton />}
        </div>
        {children}
        <div style={{ fontSize: 14, margin: 10, width: '-webkit-fill-available' }}>
          {isEditable && <RenderSaveButton />}
          {!isEditable && showEditButton && <RenderEditButton />}
        </div>
        <div style={{ height: 100 }} />
      </div>
    </>
  );
};
