import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Result } from 'antd';
import { Button, Typography } from '@material-ui/core';
import { RESET_TICKET, RESET } from '~/redux/actions';

import logo from '~/images/logo.png';
import './index.css';

const CompletedPage = ({ routePattern }) => {
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const ticket = useSelector((state) => state.ticket.ticket);
  const dispatch = useDispatch();

  const goHome = async () => {
    await dispatch({ type: RESET_TICKET });
    history.push('/home');
  };

  const logout = () => {
    dispatch({ type: RESET });
    history.push('/login');
  };

  return (
    <Result
      style={{ color: '#fff' }}
      // status="success"
      icon={<img className="logo" src={logo} alt="シルトラ" />}
      title={
        <>
          <Typography style={{ fontSize: 20, color: '#ddd' }}>
            {user.Woker_FamilyName__c || user.name}さんお疲れ様です！
          </Typography>
          {routePattern === '7' ? (
            <Typography style={{ fontSize: 18, color: '#ddd' }}>
              今回の料金計算時間は{ticket.HandedTime__c}hでした
            </Typography>
          ) : (
            <Typography style={{ fontSize: 18, color: '#ddd' }}>
              今回の料金計算距離は{ticket.mileage__c}kmでした
            </Typography>
          )}
          {ticket.standbyTime__c && (
            <Typography style={{ fontSize: 18, color: '#ddd' }}>
              待機時間は{ticket.standbyTime__c}分でした
            </Typography>
          )}
        </>
      }
      subTitle={
        <>
          <Typography style={{ fontSize: 12, color: '#ddd', marginTop: 20 }}>
            {ticket.Name}のデータ記入は完了しました
          </Typography>
        </>
      }
      extra={[
        <Button
          key={`button-logout`}
          style={{ margin: 10, fontSize: 22 }}
          onClick={logout}
          variant="contained"
          color="secondary"
          focusVisibleClassName="btn-focus"
        >
          ログアウト
        </Button>,
        <Button
          key="button-home"
          style={{ margin: 10, fontSize: 22 }}
          onClick={goHome}
          variant="contained"
          color="primary"
          focusVisibleClassName="btn-focus"
        >
          ホームに戻る
        </Button>
      ]}
    />
  );
};

export default CompletedPage;
