import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Toast, Modal } from 'antd-mobile';
import { AppBar, Toolbar, Typography, Tabs, Tab } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import { RESET_TICKET, getTicket, updateTicket } from '~/redux/actions';
import IssueTicketForm from './sections/issueTicketForm';
import IssueTicketFormNagoya from './sections/issueTicketFormNagoya';
import DepartureForm, {
  DepartureFormOnlyTime,
  DepartureFormOnlyTimeEtchu,
  DepartureFormWithEstimatedArrival
} from './sections/departureForm';
import RideForm from './sections/rideForm';
import CollectForm from './sections/collectForm';
import DeliveryForm, {
  DeliveryFormWithBackTime,
  FlightDeliveryForm
} from './sections/deliveryForm';
import WaitingForm from './sections/waitingForm';
import BackForm from './sections/backForm';
import BackFormNagoya from './sections/backFormNagoya';
import ArrivalForm from './sections/arrivalForm';
import ReturnFormNagoya, {
  ReturnFormWithTransportationExpensesNagoya
} from './sections/returnFormNagoya';
import ReturnForm, { ReturnFormWithTransportationExpenses } from './sections/returnForm';
import CompletedPage from './sections/completedPage';

import { routePatternEtchujima, routePatternNagoya } from '~/config';
const { alert } = Modal;

const statusForms = {
  IssueTicketForm: (props) => <IssueTicketForm {...props} />,
  IssueTicketFormNagoya: (props) => <IssueTicketFormNagoya {...props} />,
  DepartureForm: (props) => <DepartureForm {...props} />,
  DepartureFormWithEstimatedArrival: (props) => <DepartureFormWithEstimatedArrival {...props} />,
  DepartureFormOnlyTime: (props) => <DepartureFormOnlyTime {...props} />,
  DepartureFormOnlyTimeEtchu: (props) => <DepartureFormOnlyTimeEtchu {...props} />,
  RideForm: (props) => <RideForm {...props} />,
  CollectForm: (props) => <CollectForm {...props} />,
  DeliveryForm: (props) => <DeliveryForm {...props} />,
  DeliveryFormWithBackTime: (props) => <DeliveryFormWithBackTime {...props} />,
  FlightDeliveryForm: (props) => <FlightDeliveryForm {...props} />,
  WaitingForm: (props) => <WaitingForm {...props} />,
  BackForm: (props) => <BackForm {...props} />,
  BackFormNagoya: (props) => <BackFormNagoya {...props} />,
  ArrivalForm: (props) => <ArrivalForm {...props} />,
  ReturnForm: (props) => <ReturnForm {...props} />,
  ReturnFormNagoya: (props) => <ReturnFormNagoya {...props} />,
  ReturnFormWithTransportationExpenses: (props) => (
    <ReturnFormWithTransportationExpenses {...props} />
  ),
  ReturnFormWithTransportationExpensesNagoya: (props) => (
    <ReturnFormWithTransportationExpensesNagoya {...props} />
  ),
  CompletedPage: (props) => <CompletedPage {...props} />
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      key={`tab-${index}`}
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
};

const TicketPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const ticketError = useSelector((state) => state.ticket.error);
  const goHome = useCallback(() => {
    dispatch({ type: RESET_TICKET });
    history.push('/home');
  }, [dispatch, history]);

  const routePattern = useSelector((state) => {
    try {
      let routePatternConfig;
      if (user.location === '越中島') {
        routePatternConfig = routePatternEtchujima;
      } else if (user.location === '名古屋') {
        routePatternConfig = routePatternNagoya;
      } else {
        routePatternConfig = routePatternEtchujima;
      }
      const routes = routePatternConfig[ticket.routePatternNumber__c || '1']; // route from config
      const statusIndex = routes // index of current route phase
        .map((item) => item.title)
        .indexOf(ticket.status__c);

      if (!ticket.status__c)
        // if newly created
        return [
          {
            status: 'チケット発行',
            form: (
              <IssueTicketForm
                key="issueTicket"
                isCurrent={true}
                onSubmit={(data) => submitForm(data, routes[statusIndex + 1].title)}
              />
            )
          }
        ];

      return routes.slice(0, statusIndex + 1).map((status, idx) => {
        const isCurrent = idx === statusIndex;
        return Object({
          status: status.title,
          form: statusForms[status.form]({
            title: status.title,
            isCurrent,
            skippable: status.skippable,
            key: status.title,
            routePattern: ticket.routePatternNumber__c,
            onSubmit: (data) =>
              submitForm(data, isCurrent ? routes[statusIndex + 1].title : undefined)
          })
        });
      });
    } catch (err) {
      goHome();
    }
  });
  const [tabkey, setTabkey] = useState(routePattern.length - 1);

  const handleTabChange = (e, newTabkey) => {
    setTabkey(newTabkey);
  };

  const handleSwipe = (idx) => {
    if (idx >= routePattern.length) return;
    setTabkey(idx);
  };

  const submitForm = (data, nextStage) => {
    let status__c;
    if (
      data &&
      data.routePatternNumber__c &&
      ticket.routePatternNumber__c !== data.routePatternNumber__c
    ) {
      status__c = '出発'; // status__c goes back to 出発 if route pattern changes
    } else {
      status__c = nextStage ? nextStage : ticket.status__c;
    }

    const patchData = {
      ...data,
      status__c
    };
    dispatch(updateTicket({ ...patchData, Id: ticket.Id }));
  };

  useEffect(() => {
    try {
      dispatch(getTicket({ id }));
    } catch (err) {
      dispatch({ type: RESET_TICKET });
    }
  }, [dispatch, id]);

  useEffect(() => {
    try {
      setTabkey(routePattern.length - 1);
      if (ticketError) {
        // if (ticketError.type === "update")
        //   return Toast.fail(ticketError.message);
        // console.log(ticketError);

        goHome();
      }
      if (isLoading) Toast.loading();
      if (!isLoading) Toast.hide();
    } catch (err) {
      goHome();
    }
  }, [routePattern.length, ticketError, goHome, isLoading]);

  const confirmGoHome = () => {
    if (ticket.status__c === '作業完了') return goHome();
    alert('未完了', 'まだデータの入力が完了しておりません、そのまま戻りますか？', [
      { text: 'いいえ' },
      {
        text: 'はい',
        onPress: goHome
      }
    ]);
  };

  return (
    <>
      {routePattern && ticket && !isLoading && (
        <>
          <AppBar position="static" color="primary">
            <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                style={{ color: '#000', cursor: 'pointer' }}
                edge="start"
                onClick={confirmGoHome}>
                戻る
              </Typography>
              <div>
                <Typography style={{ fontSize: 14, marginLeft: 30, color: '#444' }}>
                  伝票番号：{ticket.Name}
                </Typography>
                <Typography style={{ fontSize: 14, marginLeft: 30, color: '#444' }}>
                  輸送拠点：{user.location}
                </Typography>
              </div>
            </Toolbar>
          </AppBar>
          <Tabs
            value={tabkey}
            onChange={handleTabChange}
            style={{ backgroundColor: '#111', color: '#ddd' }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on">
            {routePattern.map((route, idx) => (
              <Tab key={`key-tab-${idx}`} id={`tab-${idx}`} label={route.status} />
            ))}
          </Tabs>
          <SwipeableViews
            ignoreNativeScroll
            hysteresis={0.6}
            axis={'x'}
            index={tabkey}
            onChangeIndex={handleSwipe}>
            {routePattern.map((route, idx) => (
              <TabPanel
                key={`tabpanel-${idx}`}
                style={{ minHeight: '100vh' }}
                value={tabkey}
                index={idx}>
                {route.form}
              </TabPanel>
            ))}
          </SwipeableViews>
        </>
      )}
    </>
  );
};

export default TicketPage;
