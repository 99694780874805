import axios from 'axios';
import { UNAUTH_USER } from './actions';

export default {
  setupInterceptors: (store) => {
    // response interceptor
    axios.interceptors.response.use(
      (res) => {
        return res;
      },
      (err) => {
        // catch 401 or 403
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear();
          store.dispatch({ type: UNAUTH_USER });
        }
        return Promise.reject(err);
      }
    );
  }
};
