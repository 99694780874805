export const routePatternEtchujima = {
  1: [
    {
      order: 0,
      title: 'チケット発行',
      form: 'IssueTicketForm',
      skippable: false
    },
    { order: 1, title: '出発', form: 'DepartureFormWithEstimatedArrival', skippable: false },
    { order: 2, title: '経由', form: 'CollectForm', skippable: true },
    { order: 3, title: '納品', form: 'DeliveryForm', skippable: false },
    { order: 4, title: '待機', form: 'WaitingForm', skippable: false },
    { order: 5, title: '戻し', form: 'BackForm', skippable: true },
    { order: 6, title: '帰社', form: 'ReturnForm', skippable: false },
    { order: 7, title: '作業完了', form: 'CompletedPage' }
  ],
  7: [
    {
      order: 0,
      title: 'チケット発行',
      form: 'IssueTicketForm',
      skippable: false
    },
    {
      order: 1,
      title: '出発',
      form: 'DepartureFormOnlyTimeEtchu',
      skippable: false
    },
    { order: 2, title: '乗車', form: 'RideForm', skippable: true },
    {
      order: 3,
      title: '納品',
      form: 'DeliveryFormWithBackTime',
      skippable: false
    },
    {
      order: 4,
      title: '帰社',
      form: 'ReturnFormWithTransportationExpenses',
      skippable: false
    },
    { order: 5, title: '作業完了', form: 'CompletedPage' }
  ],
  8: [
    {
      order: 0,
      title: 'チケット発行',
      form: 'IssueTicketForm',
      skippable: false
    },
    { order: 1, title: '出発', form: 'DepartureFormWithEstimatedArrival', skippable: false },
    { order: 2, title: '納品', form: 'FlightDeliveryForm', skippable: false },
    { order: 3, title: '帰社', form: 'ReturnForm', skippable: false },
    { order: 4, title: '作業完了', form: 'CompletedPage' }
  ]
};

export const routePatternNagoya = {
  1: [
    {
      order: 0,
      title: 'チケット発行',
      form: 'IssueTicketFormNagoya',
      skippable: false
    },
    { order: 1, title: '出発', form: 'DepartureForm', skippable: false },
    { order: 2, title: '経由', form: 'CollectForm', skippable: true },
    { order: 3, title: '納品', form: 'DeliveryForm', skippable: false },
    { order: 4, title: '待機', form: 'WaitingForm', skippable: false },
    { order: 5, title: '戻し', form: 'BackFormNagoya', skippable: true },
    { order: 6, title: '帰社', form: 'ReturnFormNagoya', skippable: false },
    { order: 7, title: '作業完了', form: 'CompletedPage' }
  ],
  7: [
    {
      order: 0,
      title: 'チケット発行',
      form: 'IssueTicketFormNagoya',
      skippable: false
    },
    {
      order: 1,
      title: '出発',
      form: 'DepartureFormOnlyTime',
      skippable: false
    },
    {
      order: 2,
      title: '納品',
      form: 'DeliveryFormWithBackTime',
      skippable: false
    },
    {
      order: 3,
      title: '帰社',
      form: 'ReturnFormWithTransportationExpensesNagoya',
      skippable: false
    },
    { order: 4, title: '作業完了', form: 'CompletedPage' }
  ],
  8: [
    {
      order: 0,
      title: 'チケット発行',
      form: 'IssueTicketFormNagoya',
      skippable: false
    },
    { order: 1, title: '出発', form: 'DepartureForm', skippable: false },
    { order: 2, title: '納品', form: 'FlightDeliveryForm', skippable: false },
    { order: 3, title: '帰社', form: 'ReturnForm', skippable: false },
    { order: 4, title: '作業完了', form: 'CompletedPage' }
  ]
};

// Opera 8.0+
export const isOpera =
  (!!window.opr && !!window.opr.addons) ||
  !!window.opera ||
  navigator.userAgent.indexOf(' OPR/') >= 0;

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== 'undefined';

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(
    !window['safari'] ||
      (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification)
  );

// Internet Explorer 6-11
export const isIE = /*@cc_on!@*/ false || !!document.documentMode;

// Edge 20+
export const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Blink engine detection
export const isBlink = (isChrome || isOpera) && !!window.CSS;
