import {
  QUERY_TICKET_REQUEST,
  QUERY_TICKET_SUCCESS,
  QUERY_TICKET_FAILURE,
  ADD_TICKET_REQUEST,
  ADD_TICKET_SUCCESS,
  ADD_TICKET_FAILURE,
  UPDATE_TICKET_REQUEST,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_FAILURE,
  RESET_TICKET,
  RESET
} from '../actions';

const initialState = {
  ticket: {},
  error: null,
  isLoading: false,
  isUpdating: false,
  receivedAt: null
};

const ticket = (state = initialState, action) => {
  switch (action.type) {
    case QUERY_TICKET_REQUEST: {
      return {
        ticket: {},
        isLoading: true
      };
    }
    case QUERY_TICKET_SUCCESS: {
      return {
        ticket: action.payload.ticket,
        isLoading: false,
        receivedAt: action.meta.receivedAt
      };
    }
    case QUERY_TICKET_FAILURE: {
      return {
        ticket: {},
        isLoading: false,
        error: action.payload.error,
        receivedAt: action.meta.receivedAt
      };
    }
    case ADD_TICKET_REQUEST: {
      return {
        ticket: {},
        isLoading: true
      };
    }
    case ADD_TICKET_SUCCESS: {
      return {
        ticket: action.payload.ticket,
        isLoading: false,
        receivedAt: action.meta.receivedAt
      };
    }
    case ADD_TICKET_FAILURE: {
      return {
        ticket: {},
        isLoading: false,
        error: action.payload.error,
        receivedAt: action.meta.receivedAt
      };
    }
    case UPDATE_TICKET_REQUEST: {
      return {
        ticket: {},
        isLoading: true
      };
    }
    case UPDATE_TICKET_SUCCESS: {
      return {
        ticket: action.payload.ticket,
        isLoading: false,
        receivedAt: action.meta.receivedAt
      };
    }
    case UPDATE_TICKET_FAILURE: {
      return {
        ticket: {},
        isLoading: false,
        error: action.payload.error,
        receivedAt: action.meta.receivedAt
      };
    }
    case RESET_TICKET: {
      return {
        ticket: {},
        isLoading: false,
        error: null
      };
    }
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default ticket;
