import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

const resolver = (shape) => yupResolver(yup.object().shape(shape));

export const ticketNameResolver = resolver({
  name: yup.string().trim().nullable().length(6, '6桁です').required('必須項目です')
});

export const loginResolver = resolver({
  tel: yup
    .string()
    .trim()
    .nullable()
    .length(11, '電話番号11桁でお願いします')
    .required('必須項目です'),
  id: yup.string().trim().nullable().length(3, '3桁の数字です').required('必須項目です')
});

export const issueTicketResolver = resolver({
  Name: yup.string().trim().nullable().required('必須項目です'),
  manage_id__c: yup.string().trim().nullable().length(3, '3桁の数字です').required('必須項目です'),
  requestNumber__c: yup
    .string()
    .trim()
    .nullable()
    .length(12, '12桁でお願いします')
    .required('必須項目です'),
  transpotationMethod__c: yup.string().trim().nullable().required('必須項目です'),
  routePatternNumber__c: yup.string().trim().nullable().required('必須項目です'),
  registDate__c: yup.date().nullable().required('必須項目です')
});

export const issueTicketNagoyaResolver = resolver({
  Name: yup.string().trim().nullable().required('必須項目です'),
  manage_id__c: yup.string().trim().nullable().length(3, '3桁の数字です').required('必須項目です'),
  requestNumber__c: yup
    .string()
    .trim()
    .nullable()
    .length(12, '12桁でお願いします')
    .required('必須項目です'),
  transpotationMethod__c: yup.string().trim().nullable().required('必須項目です'),
  routePatternNumber__c: yup.string().trim().nullable().required('必須項目です'),
  BillTypeNA__c: yup
    .string()
    .trim()
    .nullable()
    .when('routePatternNumber__c', {
      is: '1' || '7',
      then: yup.string().trim().nullable().required('必須項目です')
    }),
  registDate__c: yup.date().nullable().required('必須項目です')
});

export const departureResolver = resolver({
  depertureTime__c: yup.date().nullable().required('必須項目です'),
  depertureMeter__c: yup.string().trim().nullable().required('必須項目です')
});

export const departureWithEstimatedArrivalResolver = resolver({
  depertureTime__c: yup.date().nullable().required('必須項目です'),
  depertureMeter__c: yup.string().trim().nullable().required('必須項目です'),
  estimatedArrivalTime__c: yup.date().nullable().required('必須項目です'),
  deliveryArea__c: yup.string().trim().nullable().required('必須項目です')
});

export const departureOnlyTimeResolver = resolver({
  depertureTime__c: yup.date().nullable().required('必須項目です')
});

export const departureOnlyTimeEtchuResolver = resolver({
  depertureTime__c: yup.date().nullable().required('必須項目です'),
  deliveryArea__c: yup.string().trim().nullable().required('必須項目です')
});

export const rideResolver = resolver({
  boardingFlightNumber__c: yup.string().nullable().required('必須項目です'),
  estimatedDepertureTime__c: yup.date().nullable().required('必須項目です'),
  estimatedArrivalTime__c: yup.date().nullable().required('必須項目です')
});

export const collectResolver = resolver({
  PickupLocation__c: yup.string().nullable(),
  contactName__c: yup.string().nullable(),
  collectionTime__c: yup.date().nullable()
});

export const deliveryResolver = resolver({
  depertureTime__c: yup.date().nullable().required('必須項目です'),
  depertureMeter__c: yup.number().nullable().required('必須項目です'),
  localArrivalTime__c: yup
    .date()
    .nullable()
    .min(yup.ref('depertureTime__c'), '出発時間より早いです')
    .required('必須項目です'),
  arrivalMeter__c: yup
    .number()
    .min(yup.ref('depertureMeter__c'), '出発時メーターより少ないです')
    .nullable()
    .required('必須項目です'),
  StandbyStaff__c: yup.boolean(),
  deliveryTime__c: yup
    .date()
    .nullable()
    .when('StandbyStaff__c', {
      is: false,
      then: yup
        .date()
        .nullable()
        .min(yup.ref('localArrivalTime__c'), '到着時間より早いです')
        .required('必須項目です')
    }),
  handedOver1__c: yup
    .string()
    .trim()
    .nullable()
    .when('StandbyStaff__c', {
      is: false,
      then: yup.string().trim().nullable().required('必須項目です')
    }),
  HandedOverDep__c: yup.string().trim().nullable(),
  deliveryRecipient__c: yup
    .string()
    .trim()
    .nullable()
    .when('StandbyStaff__c', {
      is: false,
      then: yup.string().trim().nullable().required('必須項目です')
    }),
  standbyBegin__c: yup.date().nullable()
});

export const deliveryWithBackTimeResolver = resolver({
  depertureTime__c: yup.date().nullable().required('必須項目です'),
  localArrivalTime__c: yup
    .date()
    .nullable()
    .min(yup.ref('depertureTime__c'), '出発時間より早いです')
    .required('必須項目です'),
  deliveryTime__c: yup
    .date()
    .nullable()
    .min(yup.ref('localArrivalTime__c'), '到着時間より早いです')
    .required('必須項目です'),
  handedOver1__c: yup.string().trim().nullable().required('必須項目です'),
  HandedOverDep__c: yup.string().trim().nullable(),
  deliveryRecipient__c: yup.string().trim().nullable().required('必須項目です'),
  localDepertureTime__c: yup
    .date()
    .nullable()
    .min(yup.ref('depertureTime__c'), '出発時間より早いです')
    .required('必須項目です'),
  estimatedReturnTime__c: yup.date().nullable()
});

export const flightDeliveryResolver = resolver({
  depertureMeter__c: yup.number().nullable(),
  arrivalMeter__c: yup
    .number()
    .min(yup.ref('depertureMeter__c'), '出発時メーターより少ないです')
    .nullable()
    .required('必須項目です'),
  deliveryTime__c: yup.date().nullable().required('必須項目です'),
  flightNumber__c: yup.string().nullable().required('必須項目です'),
  luggageNumber__c: yup.string().nullable().required('必須項目です'),
  advancePayment__c: yup.string().trim().nullable(),
  estimatedDepertureTime__c: yup.date().nullable().required('必須項目です'),
  estimatedArrivalTime__c: yup
    .date()
    .nullable()
    .min(yup.ref('estimatedDepertureTime__c'), '出発予定時間より早いです')
    .required('必須項目です'),
  estimatedReturnTime__c: yup.date().nullable()
});

export const waitingResolver = resolver({
  depertureTime__c: yup.date().nullable(),
  standbyEnd__c: yup.date().nullable(),
  localDepertureTime__c: yup
    .date()
    .nullable()
    .min(yup.ref('depertureTime__c'), '出発時間より早いです')
    .required('必須項目です'),
  handedOver__c: yup.string().trim().nullable(),
  estimatedReturnTime__c: yup.date().nullable().required('必須項目です')
});

export const backResolver = resolver({
  depertureMeter__c: yup.number().nullable().required('必須項目です'),
  arrivalMeter__c: yup
    .number()
    .min(yup.ref('depertureMeter__c'), '出発時メーターより少ないです')
    .nullable()
    .required('必須項目です'),
  extraditionTime__c: yup.date().nullable().required('必須項目です'),
  returnRecipient__c: yup.string().trim().nullable().required('必須項目です')
});

export const backNagoyaResolver = resolver({
  depertureMeter__c: yup.number().nullable().required('必須項目です'),
  extraditionTime__c: yup.date().nullable().required('必須項目です'),
  returnRecipient__c: yup.string().trim().nullable().required('必須項目です')
});

export const arrivalResolver = resolver({
  depertureTime__c: yup.date().nullable().required('必須項目です'),
  depertureMeter__c: yup.number().nullable().required('必須項目です'),
  localArrivalTime__c: yup
    .date()
    .nullable()
    .min(yup.ref('depertureTime__c'), '出発時間より早いです')
    .required('必須項目です'),
  arrivalMeter__c: yup
    .number()
    .min(yup.ref('depertureMeter__c'), '出発時メーターより少ないです')
    .nullable()
    .required('必須項目です'),
  deliveryTime__c: yup
    .date()
    .nullable()
    .min(yup.ref('localArrivalTime__c'), '到着時間より早いです')
    .required('必須項目です'),
  standbyBegin__c: yup.date().nullable(),
  standbyEnd__c: yup.date().nullable(),
  deliveryRecipient__c: yup.string().trim().nullable().required('必須項目です'),
  handedOver1__c: yup.string().trim().nullable().required('必須項目です'),
  localDepertureTime__c: yup
    .date()
    .nullable()
    .min(yup.ref('localArrivalTime__c'), '到着時間より早いです')
    .required('必須項目です'),
  estimatedReturnTime__c: yup
    .date()
    .nullable()
    .min(yup.ref('localDepertureTime__c'), '現地発時間より早いです')
    .required('必須項目です')
});

export const returnResolver = resolver({
  returnTime__c: yup.date().nullable().required('必須項目です'),
  tollFee__c: yup.string().trim().nullable(),
  callCharge__c: yup.string().trim().nullable(),
  advancePayment__c: yup.string().trim().nullable(),
  Notes__c: yup.string().trim().nullable().max(255, '最大255文字です')
});

export const returnFormWithTransportationExpensesResolver = resolver({
  returnTime__c: yup.date().nullable().required('必須項目です'),
  CheckInTime__c: yup.date().nullable(),
  CheckOutTime__c: yup
    .date()
    .nullable()
    .min(yup.ref('CheckInTime__c'), 'チェックイン発時間より早いです'),
  trainAirFare__c: yup.string().trim().nullable(),
  extraFare__c: yup.string().trim().nullable(),
  busTaxiFare__c: yup.string().trim().nullable(),
  callCharge__c: yup.string().trim().nullable(),
  advancePayment__c: yup.string().trim().nullable(),
  Notes__c: yup.string().trim().nullable().max(255, '最大255文字です')
});
