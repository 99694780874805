import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControlLabel, Checkbox } from '@material-ui/core';

export const CheckboxField = ({ control, name, defaultValue, label, editable, onCheck }) => {
  return (
    <div style={{ margin: 10 }}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value, name }) => (
          <FormControlLabel
            style={{ color: 'white', margin: 10 }}
            control={
              <Checkbox
                color="primary"
                disabled={!editable}
                onChange={(e) => {
                  if (e.target.checked) onCheck();
                  onChange(e.target.checked);
                }}
                onBlur={onBlur}
                checked={value}
                name={name}
              />
            }
            label={label}
          />
        )}
      />
    </div>
  );
};
