import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { InputField, NumberField } from '~/components/formComponents/input';
import { Form } from '~/components/formComponents/form';
import { DatePickerField } from '~/components/formComponents/picker';
import { backResolver } from '~/utils/yupResolver';

const BackForm = ({ title, skippable, isCurrent, onSubmit }) => {
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: backResolver
  });

  const submitForm = (data) => {
    onSubmit(data);
  };

  const skipForm = () => {
    onSubmit();
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title={title}
          skippable={skippable}
          isEditable={editable}
          isCurrent={isCurrent}
          showEditButton={true}
          onSave={handleSubmit(submitForm)}
          onSkip={skipForm}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}
        >
          <div style={{ display: 'none' }}>
            <NumberField
              control={control}
              name="depertureMeter__c"
              defaultValue={ticket.depertureMeter__c}
              inputProps={{
                disabled: true
              }}
            />
          </div>
          <NumberField
            control={control}
            name="arrivalMeter__c"
            label="現地着時メーター"
            defaultValue={ticket.arrivalMeter__c}
            format={{
              thousandSeparator: ',',
              suffix: ' km'
            }}
            inputProps={{
              placeholder: '現地着時のメーター（km）',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.arrivalMeter__c}
            clear
          />
          <DatePickerField
            control={control}
            name="extraditionTime__c"
            label="引渡完了時間"
            placeholder="引渡完了時間を選択"
            defaultValue={ticket.extraditionTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.extraditionTime__c}
            clear
            disableFuture
          />
          <InputField
            control={control}
            name="returnRecipient__c"
            label="受領者名"
            tooltip="受領者名を記入してください"
            defaultValue={ticket.returnRecipient__c}
            inputProps={{
              placeholder: '受領者名を記入してください',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.returnRecipient__c}
            clear
          />
        </Form>
      )}
    </>
  );
};

export default BackForm;
