import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { InputField, NumberField } from '~/components/formComponents/input';
import { CheckboxField } from '~/components/formComponents/checkbox';
import { Form } from '~/components/formComponents/form';
import { PickerField, DatePickerField } from '~/components/formComponents/picker';
import {
  deliveryResolver,
  deliveryWithBackTimeResolver,
  flightDeliveryResolver
} from '~/utils/yupResolver';

const DeliveryForm = ({ title, isCurrent, onSubmit }) => {
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const { control, handleSubmit, errors, reset, watch } = useForm({
    resolver: deliveryResolver
  });
  const watchHandOver = watch('handedOver1__c', ticket.handedOver1__c);
  const watchStandbyStaff = watch('StandbyStaff__c', ticket.StandbyStaff__c);

  const clearErrors = () => {
    errors.deliveryTime__c = undefined;
    errors.handedOver1__c = undefined;
    errors.deliveryRecipient__c = undefined;
  };

  const submitForm = (data) => {
    onSubmit(data);
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title={title}
          isEditable={editable}
          showEditButton={true}
          onSave={handleSubmit(submitForm)}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}
        >
          <div style={{ display: 'none' }}>
            <DatePickerField
              control={control}
              name="depertureTime__c"
              defaultValue={ticket.depertureTime__c}
              inputProps={{
                type: 'datetime-local',
                disabled: true
              }}
            />
            <NumberField
              control={control}
              name="depertureMeter__c"
              defaultValue={ticket.depertureMeter__c}
              inputProps={{
                disabled: true
              }}
            />
          </div>
          <DatePickerField
            control={control}
            name="localArrivalTime__c"
            label="現地着時間"
            placeholder="現地着時間を選択"
            defaultValue={ticket.localArrivalTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.localArrivalTime__c}
            clear
            disableFuture
          />
          <NumberField
            control={control}
            name="arrivalMeter__c"
            label="メーター"
            defaultValue={ticket.arrivalMeter__c}
            format={{
              thousandSeparator: ',',
              suffix: ' km'
            }}
            inputProps={{
              placeholder: '現地着時のメーター（km）',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.arrivalMeter__c}
            clear
          />
          <CheckboxField
            onCheck={clearErrors}
            control={control}
            name="StandbyStaff__c"
            label="保守員待ち待機"
            editable={editable}
            defaultValue={ticket.StandbyStaff__c}
          />
          <DatePickerField
            control={control}
            name="deliveryTime__c"
            label="受渡時間"
            placeholder="受渡時間を選択"
            defaultValue={ticket.deliveryTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.deliveryTime__c}
            clear
            disableFuture
            optional={watchStandbyStaff}
          />
          <PickerField
            control={control}
            name="handedOver1__c"
            label="渡し先"
            placeholder="渡し先を選択"
            defaultValue={ticket.handedOver1__c}
            data={[
              { label: '指定渡し', value: '指定渡し' },
              { label: '指定外渡し', value: '指定外渡し' }
            ]}
            inputProps={{
              disabled: !editable
            }}
            error={errors.handedOver1__c}
            clear
            optional={watchStandbyStaff}
          />
          {watchHandOver === '指定外渡し' && (
            <InputField
              control={control}
              name="HandedOverDep__c"
              label="受領者部署"
              tooltip="受領者部署を記入してください"
              defaultValue={ticket.HandedOverDep__c}
              inputProps={{
                placeholder: '受領者部署を記入してください',
                disabled: !editable,
                autoComplete: 'organization-title'
              }}
              error={errors.HandedOverDep__c}
              clear
              optional
            />
          )}
          <InputField
            control={control}
            name="deliveryRecipient__c"
            label="受領者"
            tooltip="受領者の名前を記入してください"
            defaultValue={ticket.deliveryRecipient__c}
            inputProps={{
              placeholder: '受領者の名前を記入してください',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.deliveryRecipient__c}
            clear
            optional={watchStandbyStaff}
          />
          <DatePickerField
            control={control}
            optional
            name="standbyBegin__c"
            label="待機開始時間"
            placeholder="待機開始時間を選択（オプション）"
            defaultValue={ticket.standbyBegin__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.standbyBegin__c}
            clear
            disableFuture
          />
        </Form>
      )}
    </>
  );
};

export const DeliveryFormWithBackTime = ({ title, isCurrent, onSubmit }) => {
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const { control, handleSubmit, errors, reset, watch } = useForm({
    resolver: deliveryWithBackTimeResolver
  });
  const watchHandOver = watch('handedOver1__c', ticket.handedOver1__c);

  const submitForm = (data) => {
    onSubmit(data);
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title={title}
          isEditable={editable}
          showEditButton={true}
          onSave={handleSubmit(submitForm)}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}
        >
          <div style={{ display: 'none' }}>
            <DatePickerField
              control={control}
              name="depertureTime__c"
              defaultValue={ticket.depertureTime__c}
              inputProps={{
                type: 'datetime-local',
                disabled: true
              }}
            />
          </div>
          <DatePickerField
            control={control}
            name="localArrivalTime__c"
            label="現地着時間"
            placeholder="現地着時間を選択"
            defaultValue={ticket.localArrivalTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.localArrivalTime__c}
            clear
            disableFuture
          />
          <DatePickerField
            control={control}
            name="deliveryTime__c"
            label="受渡時間"
            placeholder="受渡時間を選択"
            defaultValue={ticket.deliveryTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.deliveryTime__c}
            clear
            disableFuture
          />
          <PickerField
            control={control}
            name="handedOver1__c"
            label="渡し先"
            placeholder="渡し先を選択"
            defaultValue={ticket.handedOver1__c}
            data={[
              { label: '指定渡し', value: '指定渡し' },
              { label: '指定外渡し', value: '指定外渡し' }
            ]}
            inputProps={{
              disabled: !editable
            }}
            error={errors.handedOver1__c}
            clear
          />
          {watchHandOver === '指定外渡し' && (
            <InputField
              control={control}
              name="HandedOverDep__c"
              label="受領者部署"
              tooltip="受領者部署を記入してください"
              defaultValue={ticket.HandedOverDep__c}
              inputProps={{
                placeholder: '受領者部署を記入してください',
                disabled: !editable,
                autoComplete: 'organization-title'
              }}
              error={errors.HandedOverDep__c}
              clear
              optional
            />
          )}
          <InputField
            control={control}
            name="deliveryRecipient__c"
            label="受領者"
            tooltip="受領者の名前を記入してください"
            defaultValue={ticket.deliveryRecipient__c}
            inputProps={{
              placeholder: '受領者の名前を記入してください',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.deliveryRecipient__c}
            clear
          />
          <DatePickerField
            control={control}
            name="localDepertureTime__c"
            label="現地発時間"
            placeholder="現地発時間を選択"
            defaultValue={ticket.localDepertureTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.localDepertureTime__c}
            clear
            disableFuture
          />
          <DatePickerField
            control={control}
            name="estimatedReturnTime__c"
            label="戻り予定"
            placeholder="戻り予定時間を選択"
            defaultValue={ticket.estimatedReturnTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.estimatedReturnTime__c}
            clear
            optional
          />
        </Form>
      )}
    </>
  );
};

export const FlightDeliveryForm = ({ title, isCurrent, onSubmit }) => {
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: flightDeliveryResolver
  });

  const submitForm = (data) => {
    onSubmit(data);
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title={title}
          isEditable={editable}
          showEditButton={true}
          onSave={handleSubmit(submitForm)}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}
        >
          <div style={{ display: 'none' }}>
            <NumberField
              control={control}
              name="depertureMeter__c"
              defaultValue={ticket.depertureMeter__c}
              inputProps={{
                disabled: true
              }}
            />
          </div>
          <DatePickerField
            control={control}
            name="deliveryTime__c"
            label="受渡時間"
            placeholder="受渡時間を選択"
            defaultValue={ticket.deliveryTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.deliveryTime__c}
            clear
            disableFuture
          />
          <NumberField
            control={control}
            name="arrivalMeter__c"
            label="メーター"
            defaultValue={ticket.arrivalMeter__c}
            format={{
              thousandSeparator: ',',
              suffix: ' km'
            }}
            inputProps={{
              placeholder: '現地着時のメーター（km）',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.arrivalMeter__c}
            clear
          />
          <InputField
            control={control}
            name="flightNumber__c"
            label="便名"
            tooltip="便名を記入してください"
            defaultValue={ticket.flightNumber__c}
            inputProps={{
              placeholder: '便名を記入してください',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.flightNumber__c}
            clear
          />
          <InputField
            control={control}
            name="luggageNumber__c"
            label="荷物ナンバー"
            tooltip="?"
            defaultValue={ticket.luggageNumber__c}
            inputProps={{
              placeholder: '12345,23456',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.luggageNumber__c}
            clear
          />
          <NumberField
            control={control}
            name="advancePayment__c"
            label="立替金"
            tooltip="?"
            optional
            defaultValue={
              ticket.advancePayment__c !== null ? ticket.advancePayment__c.toString() : ''
            }
            format={{
              thousandSeparator: ',',
              prefix: '¥ '
            }}
            inputProps={{
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.advancePayment__c}
            clear
          />
          <DatePickerField
            control={control}
            name="estimatedDepertureTime__c"
            label="発車予定時間"
            tooltip="発車予定時間を選択"
            defaultValue={ticket.estimatedDepertureTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.estimatedDepertureTime__c}
            clear
          />
          <DatePickerField
            control={control}
            name="estimatedArrivalTime__c"
            label="到着予定時間"
            tooltip="到着予定時間を選択"
            defaultValue={ticket.estimatedArrivalTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.estimatedArrivalTime__c}
            clear
          />
          <DatePickerField
            control={control}
            name="estimatedReturnTime__c"
            label="戻り予定"
            placeholder="戻り予定時間を選択"
            defaultValue={ticket.estimatedReturnTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.estimatedReturnTime__c}
            clear
            optional
          />
        </Form>
      )}
    </>
  );
};

export default DeliveryForm;
