import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Result, Button } from 'antd';
import { RESET_TICKET, RESET } from '~/redux/actions';

const ErrorPage = ({ error, resetErrorBoundary }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const goHome = async () => {
    await dispatch({ type: RESET_TICKET });
    history.push('/home');
  };

  const logout = () => {
    dispatch({ type: RESET });
    history.push('/login');
  };

  return (
    <Result
      status="warning"
      title="エラーが発生しました"
      subTitle={error.message}
      extra={[
        <Button key="logout" onClick={logout}>
          ログアウト
        </Button>,
        <Button key="try-again" onClick={resetErrorBoundary}>
          もう一度試す
        </Button>,
        <Button key="return-home" onClick={goHome} type="primary">
          ホームに戻る
        </Button>
      ]}
    />
  );
};

export default ErrorPage;
