import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast, WhiteSpace } from 'antd-mobile';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Box
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { PlusOutlined, SearchOutlined, LogoutOutlined } from '@ant-design/icons';
import { queryTicket, addTicket, RESET_TICKET, UNAUTH_USER } from '~/redux/actions';
import { InputField } from '~/components/formComponents/input';
import { ticketNameResolver } from '~/utils/yupResolver';

import logo from '~/images/logo.png';

const HomePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogState, setDialogState] = useState({});
  const { control, handleSubmit, errors } = useForm({
    resolver: ticketNameResolver
  });
  const user = useSelector((state) => state.user.user);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const ticketError = useSelector((state) => state.ticket.error);

  useEffect(() => {
    try {
      if (ticketError) Toast.fail(ticketError, 2, () => dispatch({ type: RESET_TICKET }), false);
      if (isLoading) Toast.loading();
      if (!isLoading) Toast.hide();
      if (ticket.Id) history.push(`/form/${ticket.Id}`);
    } catch (err) {
      dispatch({ type: RESET_TICKET });
    }
  }, [ticket, history, dispatch, isLoading, ticketError]);

  const createTicket = () => {
    try {
      setOpenDialog(true);
      setDialogState({
        title: '新規作成',
        description: '伝票番号を入力してください',
        cancelText: 'キャンセル',
        okText: '作成',
        onOk: (data) => {
          dispatch(addTicket({ name: data.name }));
        }
      });
    } catch (err) {
      dispatch({ type: RESET_TICKET });
    }
  };
  const searchTicket = () => {
    try {
      setDialogState({
        title: '伝票照会',
        description: 'お求めの伝票番号を入力してください',
        cancelText: 'キャンセル',
        okText: '検索',
        onOk: (data) => {
          dispatch(queryTicket({ name: data.name }));
        }
      });
      setOpenDialog(true);
    } catch (err) {
      dispatch({ type: RESET_TICKET });
    }
  };

  return (
    <div>
      <div style={{ textAlign: 'center', paddingTop: '10vh' }}>
        <img style={{ maxHeight: '30vh' }} src={logo} alt="シルトラ" />
        <h2>{user.familyName || user.name}さん、お疲れ様です</h2>
        <h3>輸送拠点：{user.location}</h3>
      </div>
      <Button
        style={{ margin: 20, fontSize: 22, width: '-webkit-fill-available' }}
        color="primary"
        variant="contained"
        onClick={createTicket}
        focusVisibleClassName="btn-focus"
      >
        <PlusOutlined /> 新規作成
      </Button>
      <Button
        style={{ margin: 20, fontSize: 22, width: '-webkit-fill-available' }}
        color="default"
        variant="contained"
        onClick={searchTicket}
        focusVisibleClassName="btn-focus"
      >
        <SearchOutlined /> 伝票照会
      </Button>
      <WhiteSpace />
      <WhiteSpace />
      <WhiteSpace />
      <Button
        style={{ margin: 20, fontSize: 22, width: '-webkit-fill-available' }}
        color="secondary"
        variant="contained"
        onClick={() => dispatch({ type: UNAUTH_USER })}
        focusVisibleClassName="btn-focus"
      >
        <LogoutOutlined /> ログアウト
      </Button>
      <Dialog
        fullScreen
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{dialogState.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogState.description}</DialogContentText>
          <InputField
            control={control}
            name="name"
            label="伝票番号"
            maxLength={6}
            defaultValue=""
            error={errors.name}
            inputProps={{
              autoFocus: true,
              type: 'tel',
              placeholder: '123456',
              margin: 'dense',
              autoComplete: 'off'
            }}
            clear
          />
        </DialogContent>
        <DialogActions>
          <Grid container spacing={3} justify="space-between" alignItems="center">
            <Box clone order={{ xs: 2, sm: 1 }}>
              <Grid item={true} xs={12} sm={6}>
                <Button
                  onClick={() => setOpenDialog(false)}
                  style={{ width: '100%', fontSize: 20, padding: 5, margin: 5 }}
                  color="default"
                  focusVisibleClassName="btn-focus"
                >
                  {dialogState.cancelText}
                </Button>
              </Grid>
            </Box>
            <Box clone order={{ xs: 1, sm: 2 }}>
              <Grid item={true} xs={12} sm={6}>
                <Button
                  onClick={handleSubmit(dialogState.onOk)}
                  style={{ width: '100%', fontSize: 20, padding: 5, margin: 5 }}
                  color="primary"
                  focusVisibleClassName="btn-focus"
                >
                  {dialogState.okText}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HomePage;
