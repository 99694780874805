import { requestUser, requestTicket, retrieveTicket, postTicket, patchTicket } from './api';
import { convertLocalDateTime } from '~/utils/dates';

// action types
export const RESET = 'RESET';
export const GET_TOKEN_REQUEST = 'GET_TOKEN_REQUEST';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const UNAUTH_USER = 'UNAUTH_USER';

const getTicketFailure = (error) => {
  return {
    type: GET_TICKET_FAILURE,
    payload: {
      error
    },
    meta: {
      receivedAt: Date.now()
    }
  };
};

const getUserRequest = () => {
  // dispatch when request starts
  // toggle isLoading to true
  return {
    type: GET_USER_REQUEST
  };
};

const getUserSuccess = (user) => {
  // dispatch when request is successful
  // toggle isLoading to false
  // send user data as payload
  return {
    type: GET_USER_SUCCESS,
    payload: {
      user
    },
    meta: {
      receivedAt: Date.now()
    }
  };
};

const getUserFailure = (error) => {
  // dispatch when request fails
  // toggle isLoading to off
  // pass error to payload
  return {
    type: GET_USER_FAILURE,
    payload: {
      error
    },
    meta: {
      receivedAt: Date.now()
    }
  };
};

export const unauthUser = () => {
  return {
    type: UNAUTH_USER
  };
};

export const getUser = ({ tel, id }) => {
  // dispatch to request user data and store
  return async (dispatch) => {
    // get user info
    dispatch(getUserRequest());
    try {
      const res = await requestUser(id, tel);
      if (!res.data.login) {
        dispatch(unauthUser());
        return dispatch(getUserFailure('ログインに失敗しました'));
      }
      dispatch(getUserSuccess(res.data.login));
    } catch (err) {
      dispatch(getUserFailure(err.errors[0].message));
    }
  };
};

// ticket
export const QUERY_TICKET_REQUEST = 'QUERY_TICKET_REQUEST';
export const QUERY_TICKET_SUCCESS = 'QUERY_TICKET_SUCCESS';
export const QUERY_TICKET_FAILURE = 'QUERY_TICKET_FAILURE';
export const GET_TICKET_REQUEST = 'GET_TICKET_REQUEST';
export const GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS';
export const GET_TICKET_FAILURE = 'GET_TICKET_FAILURE';
export const ADD_TICKET_REQUEST = 'ADD_TICKET_REQUEST';
export const ADD_TICKET_SUCCESS = 'ADD_TICKET_SUCCESS';
export const ADD_TICKET_FAILURE = 'ADD_TICKET_FAILURE';
export const UPDATE_TICKET_REQUEST = 'UPDATE_TICKET_REQUEST';
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS';
export const UPDATE_TICKET_FAILURE = 'UPDATE_TICKET_FAILURE';
export const RESET_TICKET = 'RESET_TICKET';

const queryTicketRequest = () => {
  return {
    type: QUERY_TICKET_REQUEST
  };
};

const queryTicketSuccess = (ticket) => {
  return {
    type: QUERY_TICKET_SUCCESS,
    payload: {
      ticket
    },
    meta: {
      receivedAt: Date.now()
    }
  };
};

const queryTicketFailure = (error) => {
  return {
    type: QUERY_TICKET_FAILURE,
    payload: {
      error
    },
    meta: {
      receivedAt: Date.now()
    }
  };
};

export const queryTicket = ({ name }) => {
  return async (dispatch, getState) => {
    dispatch(queryTicketRequest());
    try {
      const res = await requestTicket(name);
      if (res.totalSize === 0) return dispatch(queryTicketFailure('チケットが見つかりません'));

      const ticket = res.records[0];
      const { user } = getState();
      // comparing user id with creator id
      if (ticket.manage_id__c !== user.user.driverId)
        return dispatch(queryTicketFailure('このチケット情報を見れません'));

      // to validate if the ticket is older than yesterday
      if (ticket.returnTime__c) {
        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
        const returnDate = new Date(convertLocalDateTime(ticket.returnTime__c));
        if (returnDate < yesterday) {
          return dispatch(queryTicketFailure('このチケット情報を見れません'));
        }
      }

      dispatch(queryTicketSuccess(ticket));
    } catch (err) {
      return dispatch(queryTicketFailure(err.errors[0].message));
    }
  };
};

export const getTicket = ({ id }) => {
  return async (dispatch) => {
    dispatch(queryTicketRequest());
    try {
      const res = await retrieveTicket(id);
      if (res.length === 0) return dispatch(queryTicketFailure('チケットが見つかりません'));
      dispatch(queryTicketSuccess(res));
    } catch (err) {
      return dispatch(getTicketFailure(err.errors[0].message));
    }
  };
};

const addTicketRequest = () => {
  return { type: ADD_TICKET_REQUEST };
};

const addTicketSuccess = (ticket) => {
  return {
    type: ADD_TICKET_SUCCESS,
    payload: {
      ticket
    },
    meta: {
      receivedAt: Date.now()
    }
  };
};

const addTicketFailure = (error) => {
  return {
    type: ADD_TICKET_FAILURE,
    payload: {
      error
    },
    meta: {
      receivedAt: Date.now()
    }
  };
};

export const addTicket = ({ name }) => {
  return async (dispatch, getState) => {
    dispatch(addTicketRequest());
    try {
      const records = await requestTicket(name);
      if (records.totalSize > 0)
        return dispatch(addTicketFailure('この伝票番号はすでに登録されています'));
      const { user } = getState();
      const res = await postTicket(name, user.user.driverId, user.user.id);
      dispatch(addTicketSuccess({ Id: res.id }));
    } catch (err) {
      return dispatch(addTicketFailure(err.errors[0].message));
    }
  };
};

const updateTicketRequest = () => {
  return { type: UPDATE_TICKET_REQUEST };
};

const updateTicketSuccess = (ticket) => {
  return {
    type: UPDATE_TICKET_SUCCESS,
    payload: {
      ticket
    },
    meta: {
      receivedAt: Date.now()
    }
  };
};

const updateTicketFailure = (error) => {
  return {
    type: UPDATE_TICKET_FAILURE,
    payload: {
      error
    },
    meta: {
      receivedAt: Date.now()
    }
  };
};

export const updateTicket = (ticket) => {
  return async (dispatch) => {
    dispatch(updateTicketRequest());
    try {
      const res = await patchTicket(ticket);
      dispatch(updateTicketSuccess({ ...res }));
      dispatch(getTicket({ id: ticket.Id }));
    } catch (err) {
      return dispatch(updateTicketFailure(err.errors[0].message));
    }
  };
};
