import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { NumberField, InputField } from '~/components/formComponents/input';
import { Form } from '~/components/formComponents/form';
import { DatePickerField } from '~/components/formComponents/picker';
import { returnResolver, returnFormWithTransportationExpensesResolver } from '~/utils/yupResolver';

const ReturnFormNagoya = ({ title, isCurrent, onSubmit }) => {
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: returnResolver
  });

  const submitForm = (data) => {
    onSubmit(data);
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title={title}
          isEditable={editable}
          showEditButton={true}
          onSave={handleSubmit(submitForm)}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}
        >
          <DatePickerField
            control={control}
            name="returnTime__c"
            label="帰社時間"
            tooltip="帰社時間を選択"
            defaultValue={ticket.returnTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.returnTime__c}
            clear
            disableFuture
          />
          <NumberField
            control={control}
            name="tollFee__c"
            label="通行料"
            tooltip="?"
            optional
            defaultValue={ticket.tollFee__c !== null ? ticket.tollFee__c.toString() : ''}
            format={{
              thousandSeparator: ',',
              prefix: '¥ '
            }}
            inputProps={{
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.tollFee__c}
            clear
          />
          <NumberField
            control={control}
            name="callCharge__c"
            label="電話代"
            tooltip="?"
            optional
            defaultValue={ticket.callCharge__c !== null ? ticket.callCharge__c.toString() : ''}
            format={{
              thousandSeparator: ',',
              prefix: '¥ '
            }}
            inputProps={{
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.callCharge__c}
            clear
          />
          <NumberField
            control={control}
            name="advancePayment__c"
            label="立替金"
            tooltip="?"
            optional
            defaultValue={
              ticket.advancePayment__c !== null ? ticket.advancePayment__c.toString() : ''
            }
            format={{
              thousandSeparator: ',',
              prefix: '¥ '
            }}
            inputProps={{
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.advancePayment__c}
            clear
          />
          <NumberField
            control={control}
            name="StoragePeriod__c"
            label="積置日数"
            tooltip="?"
            optional
            defaultValue={
              ticket.StoragePeriod__c !== null ? ticket.StoragePeriod__c.toString() : ''
            }
            format={{
              thousandSeparator: ',',
              suffix: ' 日'
            }}
            inputProps={{
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.StoragePeriod__c}
            clear
          />
          <InputField
            control={control}
            name="Notes__c"
            label="備考"
            tooltip="?"
            optional
            defaultValue={ticket.Notes__c}
            maxLength={255}
            format={{
              thousandSeparator: ',',
              prefix: '¥ '
            }}
            inputProps={{
              disabled: !editable,
              autoComplete: 'off',
              multiline: true,
              rows: 4,
              rowsMax: 10
            }}
            error={errors.Notes__c}
          />
        </Form>
      )}
    </>
  );
};

export const ReturnFormWithTransportationExpensesNagoya = ({ title, isCurrent, onSubmit }) => {
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: returnFormWithTransportationExpensesResolver
  });

  const submitForm = (data) => {
    onSubmit(data);
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title={title}
          isEditable={editable}
          showEditButton={true}
          onSave={handleSubmit(submitForm)}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}
        >
          <DatePickerField
            control={control}
            name="returnTime__c"
            label="帰社時間"
            tooltip="帰社時間を選択"
            defaultValue={ticket.returnTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.returnTime__c}
            clear
            disableFuture
          />
          <NumberField
            control={control}
            name="trainAirFare__c"
            label="鉄道・航空料金"
            tooltip="?"
            optional
            defaultValue={ticket.trainAirFare__c !== null ? ticket.trainAirFare__c.toString() : ''}
            format={{
              thousandSeparator: ',',
              prefix: '¥ '
            }}
            inputProps={{
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.trainAirFare__c}
            clear
          />
          <NumberField
            control={control}
            name="extraFare__c"
            label="その他交通費"
            tooltip="?"
            optional
            defaultValue={ticket.extraFare__c !== null ? ticket.extraFare__c.toString() : ''}
            format={{
              thousandSeparator: ',',
              prefix: '¥ '
            }}
            inputProps={{
              placeholder: '特急・急行・寝台・指定料金',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.extraFare__c}
            clear
          />
          <NumberField
            control={control}
            name="busTaxiFare__c"
            label="バス・タクシー料金"
            tooltip="?"
            optional
            defaultValue={ticket.busTaxiFare__c !== null ? ticket.busTaxiFare__c.toString() : ''}
            format={{
              thousandSeparator: ',',
              prefix: '¥ '
            }}
            inputProps={{
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.busTaxiFare__c}
            clear
          />
          <NumberField
            control={control}
            name="callCharge__c"
            label="電話代"
            tooltip="?"
            optional
            defaultValue={ticket.callCharge__c !== null ? ticket.callCharge__c.toString() : ''}
            format={{
              thousandSeparator: ',',
              prefix: '¥ '
            }}
            inputProps={{
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.callCharge__c}
            clear
          />
          <NumberField
            control={control}
            name="advancePayment__c"
            label="立替金"
            tooltip="?"
            optional
            defaultValue={
              ticket.advancePayment__c !== null ? ticket.advancePayment__c.toString() : ''
            }
            format={{
              thousandSeparator: ',',
              prefix: '¥ '
            }}
            inputProps={{
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.advancePayment__c}
            clear
          />
          <NumberField
            control={control}
            name="StoragePeriod__c"
            label="積置日数"
            tooltip="?"
            optional
            defaultValue={
              ticket.StoragePeriod__c !== null ? ticket.StoragePeriod__c.toString() : ''
            }
            format={{
              thousandSeparator: ',',
              suffix: ' 日'
            }}
            inputProps={{
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.StoragePeriod__c}
            clear
          />
          <InputField
            control={control}
            name="Notes__c"
            label="備考"
            tooltip="?"
            optional
            defaultValue={ticket.Notes__c}
            maxLength={255}
            format={{
              thousandSeparator: ',',
              prefix: '¥ '
            }}
            inputProps={{
              disabled: !editable,
              autoComplete: 'off',
              multiline: true,
              rows: 4,
              rowsMax: 10
            }}
            error={errors.Notes__c}
          />
        </Form>
      )}
    </>
  );
};

export default ReturnFormNagoya;
