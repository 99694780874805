import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  UNAUTH_USER,
  RESET
} from '../actions';

const initialState = {
  user: {},
  error: null,
  isLoading: false,
  receivedAt: null
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_REQUEST: {
      return {
        user: {},
        isLoading: true
      };
    }
    case GET_USER_SUCCESS: {
      return {
        user: action.payload.user,
        isLoading: false,
        receivedAt: action.meta.receivedAt
      };
    }
    case GET_USER_FAILURE: {
      return {
        user: {},
        isLoading: false,
        error: action.payload.error,
        receivedAt: action.meta.receivedAt
      };
    }
    case UNAUTH_USER: {
      return {
        user: {},
        isLoading: false,
        error: null
      };
    }
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default user;
