import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { InputField } from '~/components/formComponents/input';
import { Form } from '~/components/formComponents/form';
import { DatePickerField } from '~/components/formComponents/picker';
import { rideResolver } from '~/utils/yupResolver';

const RideForm = ({ skippable, title, isCurrent, onSubmit }) => {
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: rideResolver
  });

  const submitForm = (data) => {
    onSubmit(data);
  };

  const skipForm = () => {
    onSubmit();
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title={title}
          description="新幹線・飛行機の場合は、乗車時に入力してください"
          isEditable={editable}
          showEditButton={true}
          onSave={handleSubmit(submitForm)}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}
          skippable={skippable}
          onSkip={skipForm}
          isCurrent={isCurrent}>
          <InputField
            control={control}
            name="boardingFlightNumber__c"
            label="乗車便名"
            tooltip="乗車便名（電車・飛行機）を記入してください"
            defaultValue={ticket.boardingFlightNumber__c}
            inputProps={{
              placeholder: '乗車便名（電車・飛行機）を記入してください',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.boardingFlightNumber__c}
            clear
          />
          <DatePickerField
            control={control}
            name="estimatedDepertureTime__c"
            label="発車予定時間"
            tooltip="発車予定時間を選択"
            defaultValue={ticket.estimatedDepertureTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.estimatedDepertureTime__c}
            clear
          />
          <DatePickerField
            control={control}
            name="estimatedArrivalTime__c"
            label="到着予定時間"
            tooltip="到着予定時間を選択"
            defaultValue={ticket.estimatedArrivalTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.estimatedArrivalTime__c}
            clear
          />
        </Form>
      )}
    </>
  );
};

export default RideForm;
