import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import NetworkService from './networkService';

const reduxMiddlewares = [thunk];

if (process.env.NODE_ENV !== 'production') {
  console.log('> env: ', process.env.NODE_ENV);
  console.log('> added logging');
  reduxMiddlewares.push(logger);
}

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(...reduxMiddlewares));

NetworkService.setupInterceptors(store);

export const persistor = persistStore(store);
export default store;
