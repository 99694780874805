import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { InputField } from '~/components/formComponents/input';
import { Form } from '~/components/formComponents/form';
import { DatePickerField } from '~/components/formComponents/picker';
import { waitingResolver } from '~/utils/yupResolver';

const WaitingForm = ({ title, skippable, isCurrent, onSubmit }) => {
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: waitingResolver
  });

  const submitForm = (data) => {
    onSubmit(data);
  };

  const skipForm = () => {
    onSubmit();
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title={title}
          description="受け渡し完了後、納品フェーズにて入力してください"
          skippable={skippable}
          isEditable={editable}
          showEditButton={true}
          onSave={handleSubmit(submitForm)}
          onSkip={skipForm}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}
        >
          <div style={{ display: 'none' }}>
            <DatePickerField
              control={control}
              name="depertureTime__c"
              defaultValue={ticket.depertureTime__c}
              inputProps={{
                type: 'datetime-local',
                disabled: true
              }}
            />
          </div>
          <DatePickerField
            control={control}
            optional
            name="standbyEnd__c"
            label="待機終了時間"
            placeholder="待機終了時間を選択（オプション）"
            defaultValue={ticket.standbyEnd__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.standbyEnd__c}
            clear
            disableFuture
          />
          <DatePickerField
            control={control}
            name="localDepertureTime__c"
            label="現地発時間"
            placeholder="現地発時間を選択"
            defaultValue={ticket.localDepertureTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.localDepertureTime__c}
            clear
            disableFuture
          />
          <InputField
            control={control}
            name="handedOver__c"
            label="戻し先"
            tooltip="戻し先を記入してください"
            defaultValue={ticket.handedOver__c}
            inputProps={{
              placeholder: '戻し先を記入してください',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.handedOver__c}
            clear
            optional
          />
          <DatePickerField
            control={control}
            name="estimatedReturnTime__c"
            label="戻り予定"
            placeholder="戻り予定時間を選択"
            defaultValue={ticket.estimatedReturnTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.estimatedReturnTime__c}
            clear
          />
        </Form>
      )}
    </>
  );
};

export default WaitingForm;
