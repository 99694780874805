import React, { useState } from 'react';
import { FormControl, FormHelperText, FormControlLabel, Checkbox } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { NumberField } from '~/components/formComponents/input';
import { PickerField, DatePickerField } from '~/components/formComponents/picker';
import { Form } from '~/components/formComponents/form';
import {
  departureResolver,
  departureWithEstimatedArrivalResolver,
  departureOnlyTimeResolver,
  departureOnlyTimeEtchuResolver
} from '~/utils/yupResolver';

const DepartureForm = ({ isCurrent, onSubmit }) => {
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: departureResolver
  });

  const submitForm = (data) => {
    onSubmit(data);
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title="出発"
          isEditable={editable}
          showEditButton={true}
          onSave={handleSubmit(submitForm)}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}>
          <DatePickerField
            control={control}
            name="depertureTime__c"
            label="出発時間"
            placeholder="出発時間を選択"
            defaultValue={ticket.depertureTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.depertureTime__c}
            clear
            disableFuture
          />
          <NumberField
            control={control}
            name="depertureMeter__c"
            label="メーター"
            tooltip="出発時のメーター（km）"
            defaultValue={ticket.depertureMeter__c || ''}
            maxLength={15}
            format={{
              thousandSeparator: ',',
              suffix: ' km'
            }}
            inputProps={{
              placeholder: '出発時のメーター（km）',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.depertureMeter__c}
            clear
          />
        </Form>
      )}
    </>
  );
};

export const DepartureFormWithEstimatedArrival = ({ isCurrent, onSubmit }) => {
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: departureWithEstimatedArrivalResolver
  });
  const [ready, setReady] = useState(false);
  const [notReadyError, setNotReadyError] = useState(false);

  const submitForm = (data) => {
    if (!ready) return setNotReadyError(true);
    onSubmit(data);
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title="出発"
          isEditable={editable}
          showEditButton={true}
          onSave={handleSubmit(submitForm)}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}>
          <DatePickerField
            control={control}
            name="depertureTime__c"
            label="出発時間"
            placeholder="出発時間を選択"
            defaultValue={ticket.depertureTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.depertureTime__c}
            clear
            disableFuture
          />
          <NumberField
            control={control}
            name="depertureMeter__c"
            label="メーター"
            tooltip="出発時のメーター（km）"
            defaultValue={ticket.depertureMeter__c || ''}
            maxLength={15}
            format={{
              thousandSeparator: ',',
              suffix: ' km'
            }}
            inputProps={{
              placeholder: '出発時のメーター（km）',
              disabled: !editable,
              autoComplete: 'off'
            }}
            error={errors.depertureMeter__c}
            clear
          />
          <PickerField
            control={control}
            name="deliveryArea__c"
            label="届け先地区"
            tooltip="届け先地区"
            defaultValue={ticket.deliveryArea__c}
            data={[
              { label: '東京都', value: '東京都' },
              { label: '神奈川県', value: '神奈川県' },
              { label: '千葉県', value: '千葉県' },
              { label: '埼玉県', value: '埼玉県' },
              { label: '茨城県', value: '茨城県' },
              { label: '群馬県', value: '群馬県' },
              { label: '栃木県', value: '栃木県' },
              { label: 'その他', value: 'その他' }
            ]}
            inputProps={{
              disabled: !editable
            }}
            error={errors.deliveryArea__c}
          />
          <DatePickerField
            control={control}
            name="estimatedArrivalTime__c"
            label="到着予定時間"
            tooltip="到着予定時間を選択"
            defaultValue={ticket.estimatedArrivalTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.estimatedArrivalTime__c}
            clear
          />
          <FormControl required error={notReadyError}>
            <FormControlLabel
              style={{ color: 'white', margin: 10 }}
              control={
                <Checkbox
                  color="primary"
                  disabled={!editable}
                  checked={ready}
                  onChange={(e) => {
                    setReady(e.target.checked);
                    setNotReadyError(!e.target.checked);
                  }}
                />
              }
              label="荷物、特記事項、ナビゲーション入力出発前確認OK！"
            />
            {notReadyError && <FormHelperText>チェックしてください</FormHelperText>}
          </FormControl>
        </Form>
      )}
    </>
  );
};

export const DepartureFormOnlyTimeEtchu = ({ isCurrent, onSubmit }) => {
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: departureOnlyTimeEtchuResolver
  });

  const [ready, setReady] = useState(false);
  const [notReadyError, setNotReadyError] = useState(false);

  const submitForm = (data) => {
    if (!ready) return setNotReadyError(true);
    onSubmit(data);
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title="出発"
          isEditable={editable}
          showEditButton={true}
          onSave={handleSubmit(submitForm)}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}>
          <DatePickerField
            control={control}
            name="depertureTime__c"
            label="出発時間"
            placeholder="出発時間を選択"
            defaultValue={ticket.depertureTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.depertureTime__c}
            clear
            disableFuture
          />
          <PickerField
            control={control}
            name="deliveryArea__c"
            label="届け先地区"
            tooltip="届け先地区"
            defaultValue={ticket.deliveryArea__c}
            data={[
              { label: '東京都', value: '東京都' },
              { label: '神奈川県', value: '神奈川県' },
              { label: '千葉県', value: '千葉県' },
              { label: '埼玉県', value: '埼玉県' },
              { label: '茨城県', value: '茨城県' },
              { label: '群馬県', value: '群馬県' },
              { label: '栃木県', value: '栃木県' },
              { label: 'その他', value: 'その他' }
            ]}
            inputProps={{
              disabled: !editable
            }}
            error={errors.deliveryArea__c}
          />
          <FormControl required error={notReadyError}>
            <FormControlLabel
              style={{ color: 'white', margin: 10 }}
              control={
                <Checkbox
                  color="primary"
                  disabled={!editable}
                  checked={ready}
                  onChange={(e) => {
                    setReady(e.target.checked);
                    setNotReadyError(!e.target.checked);
                  }}
                />
              }
              label="荷物、特記事項、ナビゲーション入力出発前確認OK！"
            />
            {notReadyError && <FormHelperText>チェックしてください</FormHelperText>}
          </FormControl>
        </Form>
      )}
    </>
  );
};

export const DepartureFormOnlyTime = ({ isCurrent, onSubmit }) => {
  const [editable, setEditable] = useState(isCurrent);
  const ticket = useSelector((state) => state.ticket.ticket);
  const isLoading = useSelector((state) => state.ticket.isLoading);
  const { control, handleSubmit, errors, reset } = useForm({
    resolver: departureOnlyTimeResolver
  });

  const submitForm = (data) => {
    onSubmit(data);
  };

  const resetForm = () => {
    reset();
    if (!isCurrent) setEditable(false);
  };

  return (
    <>
      {ticket && !isLoading && (
        <Form
          title="出発"
          isEditable={editable}
          showEditButton={true}
          onSave={handleSubmit(submitForm)}
          onCancel={resetForm}
          onEdit={() => setEditable(true)}>
          <DatePickerField
            control={control}
            name="depertureTime__c"
            label="出発時間"
            placeholder="出発時間を選択"
            defaultValue={ticket.depertureTime__c}
            inputProps={{
              type: 'datetime-local',
              disabled: !editable
            }}
            error={errors.depertureTime__c}
            clear
            disableFuture
          />
        </Form>
      )}
    </>
  );
};

export default DepartureForm;
