/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const login = /* GraphQL */ `
  query Login($input: LoginInput!) {
    login(input: $input) {
      id
      driverId
      name
      location
      familyName
      phone
    }
  }
`;
export const query = /* GraphQL */ `
  query Query($input: QueryInput!) {
    query(input: $input)
  }
`;
export const requestTicket = /* GraphQL */ `
  query RequestTicket($input: RequestTicketInput!) {
    requestTicket(input: $input) {
      totalSize
      records {
        Id
        Name
        manage_id__c
        returnTime__c
      }
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($input: GetTicketInput!) {
    getTicket(input: $input)
  }
`;
export const createTicket = /* GraphQL */ `
  query CreateTicket($input: CreateTicketInput!) {
    createTicket(input: $input)
  }
`;
export const updateTicket = /* GraphQL */ `
  query UpdateTicket($input: AWSJSON!) {
    updateTicket(input: $input)
  }
`;
