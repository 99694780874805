import React from 'react';
import { Controller } from 'react-hook-form';
import { Space } from 'antd';
import { TextField, InputAdornment, IconButton, Chip } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import NumberFormat from 'react-number-format';

import Autocomplete from '@material-ui/lab/Autocomplete';

export const InputField = ({
  control,
  name,
  defaultValue,
  error,
  label,
  inputProps,
  maxLength,
  optional,
  clear
}) => {
  const handleClickClear = () => {
    control.setValue(name, '');
  };

  return (
    <div style={{ margin: 10 }}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        render={(props) => (
          <>
            <TextField
              style={{ marginTop: 10 }}
              label={
                <Space>
                  {label}
                  {optional ? '(任意)' : ''}
                </Space>
              }
              fullWidth={true}
              variant="outlined"
              error={typeof error !== 'undefined'}
              helperText={error && error.message}
              InputProps={{
                endAdornment: control.getValues(name) && clear && !inputProps.disabled && (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear" onClick={handleClickClear}>
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              inputProps={{
                maxLength,
                style: {
                  fontSize: 18
                }
              }}
              {...inputProps}
              {...props}
            />
          </>
        )}
      />
    </div>
  );
};

export const NumberField = ({
  control,
  name,
  defaultValue,
  error,
  label,
  inputProps,
  maxLength,
  optional,
  format,
  clear
}) => {
  const handleChange = (e) => {
    control.setValue(name, e.target.value.replace(/[^0-9]+/g, ''));
  };

  const handleClickClear = () => {
    control.setValue(name, '');
  };

  return (
    <div style={{ margin: 10 }}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue || ''}
        render={(props) => (
          <NumberFormat
            style={{ marginTop: 10 }}
            {...format}
            label={
              <Space>
                {label}
                {optional ? '(任意)' : ''}
              </Space>
            }
            fullWidth={true}
            variant="outlined"
            error={typeof error !== 'undefined'}
            helperText={error && error.message}
            {...inputProps}
            {...props}
            onChange={handleChange}
            customInput={TextField}
            type="tel"
            InputProps={{
              endAdornment: control.getValues(name) && !inputProps.disabled && clear && (
                <InputAdornment position="end">
                  <IconButton aria-label="clear" onClick={handleClickClear}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              )
            }}
            inputProps={{
              maxLength,
              style: {
                fontSize: 18
              }
            }}
          />
        )}
      />
    </div>
  );
};

export const TagField = ({
  control,
  name,
  defaultValue,
  error,
  label,
  inputProps,
  maxLength,
  optional,
  format
}) => {
  const handleChange = (e, value) => {
    control.setValue(name, value.map((x) => x.replace(/[^0-9]+/g, '')).join(','));
  };

  return (
    <div style={{ margin: 10 }}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{
          validate: (data) => {
            if (data.length === 0) return false;
          }
        }}
        render={(onChange, ...props) => (
          <Autocomplete
            {...props}
            onChange={handleChange}
            defaultValue={defaultValue === null ? [] : defaultValue.split(',')}
            multiple
            freeSolo
            id="autocomplete"
            options={[]}
            {...inputProps}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <NumberFormat
                {...params}
                {...format}
                customInput={TextField}
                style={{ marginTop: 10 }}
                type="number"
                label={
                  <Space>
                    {label}
                    {optional ? '(任意)' : ''}
                  </Space>
                }
                fullWidth={true}
                variant="outlined"
                error={typeof error !== 'undefined'}
                helperText={error && error.message}
              />
            )}
          />
        )}
      />
    </div>
  );
};
