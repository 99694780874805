import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import RequireAuth from './requireAuth';
import LoginPage from '~/pages/login';
import FormPage from '~/pages/form';
import HomePage from '~/pages/home';
import ErrorPage from '~/pages/error';
import NotFoundPage from '~/pages/notFound';

export const Routes = () => {
  return (
    <HashRouter>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Switch>
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/form" component={FormPage} />
          <Route exact path="/form/:id" component={RequireAuth(FormPage)} />
          <Route exact path="/home" component={RequireAuth(HomePage)} />
          <Redirect exact from="/" to="/home" />
          <Route component={NotFoundPage} />
        </Switch>
      </ErrorBoundary>
    </HashRouter>
  );
};
