import {
  GET_TOKEN_REQUEST,
  GET_TOKEN_SUCCESS,
  GET_TOKEN_FAILURE,
  UNAUTH_USER,
  RESET
} from '../actions';

const initialState = {
  session: {},
  error: null,
  isLoading: false,
  isLogged: false,
  receivedAt: null
};

const session = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOKEN_REQUEST: {
      return {
        session: {},
        isLoading: true
      };
    }
    case GET_TOKEN_SUCCESS: {
      return {
        session: action.payload.session,
        isLoading: false,
        receivedAt: action.meta.receivedAt,
        isLogged: true
      };
    }
    case GET_TOKEN_FAILURE: {
      return {
        session: {},
        isLoading: false,
        error: action.payload.error,
        receivedAt: action.meta.receivedAt,
        isLogged: false
      };
    }
    case UNAUTH_USER: {
      return {
        session: {},
        isLoading: false,
        error: null,
        isLogged: false
      };
    }
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default session;
